import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Bio from '../components/Bio'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { rhythm } from '../utils/typography'

import icon from '../../static/favicon.ico'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet
          link={[
            {
              rel: 'shortcut icon',
              type: 'image/png',
              href: `${icon}`,
            },
          ]}
        >
          <meta
            property="og:image"
            content={`https://alexkondov.com${this.props.data.cover.childImageSharp.fluid.src}`}
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta
            property="og:title"
            content="Alex Kondov's Personal Blog"
          />
          <meta
            property="og:description"
            content="Thoughts on programming, writing and the things in between."
          />
          <meta property="og:url" content="https://alexkondov.com" />
          <meta
            name="twitter:card"
            content="summary_large_image"
          ></meta>
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="en_US" />
        </Helmet>
        <h2 className="section-title">Articles</h2>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <Link to={node.fields.slug}>
              <div
                key={node.fields.slug}
                className="article-list-item"
              >
                <h3 className="article-list-title">{title}</h3>
                <small>{node.frontmatter.date}</small>
                <div className="divider"></div>
                <p
                  dangerouslySetInnerHTML={{ __html: node.excerpt }}
                />
              </div>
            </Link>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    cover: file(absolutePath: { regex: "/cover.png/" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { tags: { nin: "fiction" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
          }
        }
      }
    }
  }
`
